import { Injectable } from '@angular/core';
import { TokenRepositoryService } from '../repositories/token-repository.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { WebApiError } from '../models/web-api-error';
import { Login } from '../models/login';
import { Token } from '../models/token';
import { UserProfile } from '../models/userprofile';
import { CookieService } from 'ngx-cookie-service';

const COOKIE_NAME:string = 'tap-app';
const TOKEN:string = 'token';
const CURRENT_USER:string = 'currentUser';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private loggedIn = new BehaviorSubject<boolean>(false);

    constructor(
        private tokenRepository: TokenRepositoryService,
        private cookieService: CookieService
        ){

    }

    isLoggedIn(model: Token) {
        return new Observable((observer) => {
            this.tokenRepository.verify(model).take(1).subscribe(data => {
                observer.next();
                observer.complete();
            }, (err: HttpErrorResponse) => {
                const errorMsgs: WebApiError = err.error;
                observer.error(errorMsgs.non_field_errors);
                observer.next();
                observer.complete();
            });
        });
    }


    getUserProfile():UserProfile{
        let currentUserSession = null;
        if(this.cookieService.check(COOKIE_NAME)){
            currentUserSession = this.cookieService.get(COOKIE_NAME);
            sessionStorage.setItem(CURRENT_USER, currentUserSession);
        }else{
            currentUserSession = sessionStorage.getItem(CURRENT_USER);
        }
        return (typeof currentUserSession!='undefined' && currentUserSession) ? new UserProfile(currentUserSession) : null;
    }


    login(model: Login) {
        const vm = this;
        return new Observable((observer) => {
            // Session from cookie
            if(this.cookieService.check(TOKEN)){
                sessionStorage.setItem(TOKEN, this.cookieService.get(TOKEN));
                observer.next();
                observer.complete();
                return;
            }

            this.tokenRepository.get(model).take(1).subscribe(data => {
                vm.setToken(data.token).then(() => {
                    observer.next();
                    observer.complete();
                }).catch((err) => {
                    const errorMsgs: WebApiError = err.error;
                    observer.error(errorMsgs.non_field_errors);
                    observer.next();
                    observer.complete();
                })
            }, (err: HttpErrorResponse) => {
                if (err.status === 0) {
                    observer.error('Server Unreachable - Contact Support');
                    observer.next();
                    observer.complete();
                } else {
                    const errorMsgs: WebApiError = err.error;
                    observer.error(errorMsgs.non_field_errors);
                    observer.next();
                    observer.complete();
                }
            });
        });
    }


    refreshToken(model: Token): Observable<string> {
        return new Observable((observer) => {
            this.tokenRepository.refresh(model).take(1).subscribe(data => {
                sessionStorage.setItem(TOKEN, data.token);
                this.cookieService.set(TOKEN, data.token);

                observer.next(data.token);
                observer.complete();
            }, (err: HttpErrorResponse) => {
                observer.error(err.error);
                observer.next();
                observer.complete();
            });
        });
    }


    checkSocialLogin(social_token:string){
        const vm = this;

        return new Promise((resolve, reject) =>{
            this.tokenRepository.verify(new Token(social_token)).take(1).subscribe(() => {
                vm.setToken(social_token).then(() => {
                    resolve();
                }).catch((err) => {
                    vm.logout();
                    reject(err);
                })
            }, err => {
                vm.logout();
                reject(err);
            })
        });
    }


    logout() {
        if(this.cookieService.check(TOKEN)){
            const token = this.cookieService.get(TOKEN);

            this.tokenRepository.logout(new Token(token)).take(1).subscribe(() => { });
        }

        sessionStorage.clear();
        this.cookieService.delete(TOKEN);
        this.cookieService.delete(COOKIE_NAME);

        this.loggedIn.next(false);
    }


    /**
     *
     * @param token
     */
    private async setToken(token:string){
        return new Promise((resolve, reject) => {
            sessionStorage.setItem(TOKEN, token);
            this.cookieService.set(TOKEN, token);

            this.tokenRepository.profile().take(1).subscribe(profile => {
                let currentUserSession = JSON.stringify(profile[0]);
                sessionStorage.setItem(CURRENT_USER, currentUserSession);
                this.cookieService.set(COOKIE_NAME, currentUserSession );

                resolve();
            }, err => {
                reject(err)
            });
        });
    }


}
