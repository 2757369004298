import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Token } from '../models/token';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

const TOKEN:string = "token";

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private authService: AuthService, 
        private router: Router,
        private cookieService: CookieService) { }

    canActivate(): Observable<boolean> {
        return new Observable((observer) => {
            let token = sessionStorage.getItem(TOKEN);
            if(this.cookieService.check(TOKEN)){
                token = this.cookieService.get(TOKEN);
            }

            this.authService.isLoggedIn(new Token(token)).subscribe(() => {
                observer.next(true);
                observer.complete();
            }, err => {
                this.router.navigate(['/login']);
                observer.next(false);
                observer.complete();
            });
        });
    }
}
