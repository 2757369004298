import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ThemeComponent } from './theme/theme.component';
import { AuthGuardService as AuthGuard } from './auth/services/auth-guard.service';

const routes: Routes = [
  { path: 'login', loadChildren: './auth/login/login.module#LoginModule' },
  { path: 'logout', loadChildren: './auth/logout/logout.module#LogoutModule' },
  { path: 'forgot-password', loadChildren: './auth/forgot-password/forgot-password.module#ForgotPasswordModule' },
  { path: 'reset-password/:code*', loadChildren: './auth/reset-password/reset-password.module#ResetPasswordModule' },
  {
    path: '',
    component: ThemeComponent,
    canActivate: [AuthGuard],
    children: [
      // Talent Assessment
      {
        path: 'talent-assessment',
        loadChildren: './theme/pages/talent-assessment/talent-assessment.module#TalentAssessmentModule',
        runGuardsAndResolvers: 'always'
      },
      { path: 'change-password',
        loadChildren: './theme/pages/change-password/change-password.module#ChangePasswordModule' ,
        runGuardsAndResolvers: 'always'
      },
      /*
      {
        path: 'talent-assessment/report',
        loadChildren: './theme/pages/talent-assessment/talent-assessment-report/talent-assessment-report.module#TalentAssessmentReportModule',
        runGuardsAndResolvers: 'always'
      },
      */

      {
        path: '',
        redirectTo: 'talent-assessment',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'talent-assessment',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
