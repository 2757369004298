import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { Token } from '../models/token';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const jwtHelper = new JwtHelperService();
        let token = sessionStorage.getItem('token');

        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.auth.logout();
                return;
            }

            const expirationDate: Date = jwtHelper.getTokenExpirationDate(token);
            const difMilliseconds: number = new Date(expirationDate.valueOf() - Date.now().valueOf()).valueOf();
            // ? is ticket about to expire
            if (difMilliseconds < new Date(1970, 1, 1, 0, environment.tokenRenewMinutes).valueOf()) {
                sessionStorage.removeItem('token');
                this.auth.refreshToken(new Token(token)).subscribe((data) => {
                    token = data;
                }, err => {
                    console.log('Error: ' + err);
                });
            }

            const cloned = request.clone({
                headers: request.headers.set('Authorization', 'JWT ' + token)
            });

            return next.handle(cloned);
        } else {
            return next.handle(request);
        }
    }
}
