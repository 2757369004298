import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import HeroBarInbox from '../../../entities/herobar/inbox';
import HeroBarActionItem from '../../../entities/herobar/action_item';
import HeroBarMessage from '../../../entities/herobar/message';
import { AuthService } from '../../../auth/services/auth.service';
import { UserProfile } from '../../../auth/models/userprofile';
//import { MessageComponent } from './message.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  isNavbarCollapsed = true;
  appTitle:string = ''

  messageTxt:string = "";

  userProfile:UserProfile = null;

  constructor(private router: Router, 
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private authService:AuthService) { }

  ngOnInit() {
    this.userProfile = this.authService.getUserProfile();
  }

  onLogout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  goToContent(content_id){
    console.log('goToContent', content_id);
  }
}
