import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
//import { MessageComponent } from './navbar/message.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule
  ],
  exports: [NavbarComponent, SidebarComponent, FooterComponent],
  declarations: [NavbarComponent, SidebarComponent, FooterComponent]
})
export class LayoutModule { }
