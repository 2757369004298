import { NgModule } from "@angular/core";
//import { EJ_GANTT_COMPONENTS } from "ej-angular2/src/ej/gantt.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxEditorModule } from "ngx-editor";

import { ComponentCallService } from "./utilities/componentCallService";
import { TalentAssessmentService } from "../../services/talent-assessment.service";


@NgModule({
  providers: [ComponentCallService],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    NgxEditorModule,
    TalentAssessmentService
  ],
  declarations: [
    //EJ_GANTT_COMPONENTS,
  ],
  exports: [
    //EJ_GANTT_COMPONENTS,
  ]
})
export class SharedModule {}
