import { NgModule } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import "rxjs/add/observable/throw";

@NgModule({
  providers: []
})
export class TalentAssessmentService {
  private diagramUrl: string = environment.assessmentApiUrl + '/api/v1/tap/';

  constructor(public http: HttpClient) {}

  /*------------------------------------------------------------------------------------------------------*/
  /*                                                                                   Get all Questions   */
  public get_assessments_by_user(): Observable<any> {
    let url = this.diagramUrl + 'assessment-by-user';

    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleErrorObservable);
  }

  public get_assessment_questions_by_user(assessment_id:number, manager_of:number|null = null): Observable<any> {
    let url = this.diagramUrl + `assessment-by-user/${assessment_id}`;
    if(manager_of !== null){
      url += `/${manager_of}`;
    }

    return this.http
      .get(url)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleErrorObservable);
  }

  public save_user_answers(assessment_id:number, answers, manager_of:number|null = null, submit:boolean = false): Observable<any>{
    let url = this.diagramUrl + `assessment-by-user/${assessment_id}`;
    if(manager_of !== null){
      url += `/${manager_of}`;
    }

    let data = {
      answers: answers
    }
    if(submit){
      data['submit'] = submit;
    }
    
    return this.http
      .post(url, data)
      .map((response: any) => {
        return response;
      })
      .catch(this.handleErrorObservable);
  }
  /*------------------------------------------------------------------------------------------------------*/

  // Log this
  private handleErrorObservable(error: Response | any) {
    return Observable.throwError(error);
  }
}
