import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Login } from '../models/login';
import { Token } from '../models/token';
import { ForgotPassword } from '../models/forgot-password';
import { ResetPassword } from '../models/reset-password';
import { ChangePassword } from '../../models/change-password';

//const url = environment.webapiUrl;

// APUNTAR A PROD MIENTRAS TRABAJAMOS
const url = "https://api.tap.dms.thehackettgroup.com";
const postOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class TokenRepositoryService {

    constructor(private httpClient: HttpClient) { }

    get(model: Login): Observable<Token> {
        return this.httpClient.post<Token>(`${url}/api-token-auth/`, model, postOptions);
    }

    refresh(token: Token): Observable<Token> {
        return this.httpClient.post<Token>(`${url}/api-token-refresh/`, token, postOptions);
    }

    verify(token: Token): Observable<Token> {
        return this.httpClient.post<Token>(`${url}/api-token-verify/`, token, postOptions);
    }

    logout(token: Token): Observable<Token> {
        return this.httpClient.post<Token>(`${url}/api-logout/`, token, postOptions);
    }

    forgotPassword(model: ForgotPassword){
      return this.httpClient.post<Token>(`${url}/api/v1/user/forgot_password/`, model, postOptions);
    }

    resetPassword(model: ResetPassword){
      return this.httpClient.post<Token>(`${url}/api/v1/user/reset_password/`, model, postOptions);
    }

    changePassword(model: ChangePassword){
      return this.httpClient.post<Token>(`${url}/api/v1/user/change_password/`, model, postOptions);
    }

    profile(): Observable<any> {
        return this.httpClient.get<any>(`${url}/api/v1/user/`); // TODO user
    }
}
