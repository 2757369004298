import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeComponent implements OnInit, AfterViewInit {
  @ViewChild('container', { static: true }) container:ElementRef;

  constructor(private router: Router) { }

  ngOnInit() {

    
  }
  ngAfterViewInit(){

    // Make main class="container" to fill all height of the browser.
    let elSearch = document.getElementsByTagName('main');
    if(elSearch.length > 0){
      let main = elSearch[0];
      if(main){
        // 53px is the navbar. 70px it's to test.
        //main.style.height = `calc(${window.innerHeight}px - 75px)`;
        this.container.nativeElement.style.height = `calc(${window.innerHeight}px - 75px)`;
      }
    }
    
  }

}
