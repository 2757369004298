export class UserProfile{

    public firstname:string;
    public lastname:string;
    public email:string;

    public organizationName:string;
    public socialauth: boolean;

    constructor(data:string) {
        if(data == null){
            return;
        }

        let tmp = JSON.parse(data);
        if(tmp){
            this.firstname = tmp.first_name;
            this.lastname = tmp.last_name;
            this.email = tmp.email;
            this.organizationName = tmp.profilemodel ? tmp.profilemodel.organization : null;
            this.socialauth = tmp.social_auth
          }
    }
}
