import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  isRouteActive(url) {
    return [
      "circle-side-icon",
      this.router.url.includes(url) || url.includes(this.router.url)
        ? "circle-side-icon-active"
        : ""
    ];
  }
}
